import { IExpandedTextAdTemplate, ITemplatePart } from "../../interfaces/DynamicCampaigns/IDynamicCampaign";
import Mustache from "../../utils/Mustache";
import moment from "moment";
import { IConditionable, IConditionableField, IConditionableParameter, IConditionalField } from "../useConditionals";
export default class ResponsiveAdPreviewFromTemplate {
    protected h1: ITemplatePart[];
    protected h2: ITemplatePart[];
    protected h3: ITemplatePart[];
    protected d1: ITemplatePart[];
    protected d2: ITemplatePart[];

    protected headlines: ITemplatePart[];
    protected descriptions: ITemplatePart[];
    protected final_url: ITemplatePart[];
    protected paths: ITemplatePart[];
    protected unpinned: ITemplatePart[];

    protected ad: IExpandedTextAdTemplate;
    constructor(ad: IExpandedTextAdTemplate) {
        this.ad = ad;
        this.h1 = ad.parts.filter((part) => part.pinnedField === "h1");
        this.h2 = ad.parts.filter((part) => part.pinnedField === "h2");
        this.h3 = ad.parts.filter((part) => part.pinnedField === "h3");
        this.d1 = ad.parts.filter((part) => part.pinnedField === "d1");
        this.d2 = ad.parts.filter((part) => part.pinnedField === "d2");

        this.headlines = ad.parts.filter((part) => part.field === "headline");
        this.descriptions = ad.parts.filter((part) => part.field === "description");
        this.final_url = ad.parts.filter((part) => part.field === "final_url");
        this.paths = ad.parts.filter((part) => part.field === "path");
        this.unpinned = ad.parts.filter(
            (part) => !part.pinnedField && ["headline", "description"].includes(part.field)
        );
    }

    public getAd(): IExpandedTextAdTemplate {
        return this.ad;
    }

    protected conditionPassesWithValue(
        field: string,
        operator: string,
        valueFromCondition: string[] | string | number,
        itemValue: string | number
    ) {
        if (field === "Date") {
            // Date isn't a field that exists in the inventory, so to approximate what the API does we set
            // the itemValue to the current time. And use Unix time for easy numerical comparison.
            itemValue = moment().unix();
            valueFromCondition = moment(valueFromCondition as string).unix();
        }

        let valueFromConditionAsNumber = parseInt(valueFromCondition as string);
        let valueFromConditionAsString = String(valueFromCondition);
        let valueFromItemAsNumber = parseInt(itemValue as string);
        let valueFromItemAsString = String(itemValue);

        switch (operator) {
            case "EQUAL":
            case "EQUALS":
                return itemValue == valueFromCondition;
            case "NOT_EQUAL":
            case "NOT_EQUALS":
                return itemValue != valueFromCondition;
            case "LESS_THAN":
                return isNaN(valueFromItemAsNumber) ? false : itemValue < valueFromConditionAsNumber;

            case "GREATER_THAN":
                return isNaN(valueFromItemAsNumber) ? false : itemValue > valueFromConditionAsNumber;
            case "LESS_THAN_EQUAL":
            case "LESS_THAN_OR_EQUAL":
                return isNaN(valueFromConditionAsNumber) ? false : itemValue <= valueFromConditionAsNumber;
            case "GREATER_THAN_EQUAL":
            case "GREATER_THAN_OR_EQUAL":
                return isNaN(valueFromConditionAsNumber) ? false : itemValue >= valueFromConditionAsNumber;
            case "ENDS_WITH":
                return valueFromItemAsString.endsWith(valueFromConditionAsString);
            case "LIKE":
            case "IN":
            case "CONTAINS":
                if (valueFromConditionAsString.includes(",")) {
                    // Sometimes like values can be arrays, so we need to check if the value is in the array
                    let values = valueFromConditionAsString.split(",");
                    return values.includes(valueFromItemAsString);
                }

                return valueFromItemAsString.includes(valueFromConditionAsString);
            case "DOES_NOT_CONTAIN":
            case "NOTIN":
            case "NOT_IN":
                if (valueFromConditionAsString.includes(",")) {
                    // Sometimes like values can be arrays, so we need to check if the value is in the array
                    let values = valueFromConditionAsString.split(",");
                    return !values.includes(valueFromItemAsString);
                }
            case "NOTLIKE":
                return !valueFromItemAsString.includes(valueFromConditionAsString);
            case "STARTS_WITH":
                return valueFromItemAsString.startsWith(valueFromConditionAsString);

            case "IN_LIKE":
            case "NOTIN_LIKE":
                // IN LIKE, and NOTIN LIKE assumes that the representative item is an array of strings, and we're looking for a partial match on one of the strings.
                if (!Array.isArray(valueFromCondition)) {
                    return false;
                }

                if (operator === "NOTIN_LIKE") {
                    return (
                        valueFromCondition.filter((item: string) => item.includes(valueFromItemAsString)).length === 0
                    );
                }

                return valueFromCondition.filter((item: string) => item.includes(valueFromItemAsString)).length > 0;
            default:
                return false;
        }
    }
    public doAnyConditionsPassForPartAndItem(part: IConditionableField, representativeItem: any): boolean {
        if (part.conditionals.length === 0) {
            return true;
        }

        let passingConditions = 0;

        for (let conditionIndex in part.conditionals) {
            const condition = part.conditionals[conditionIndex];

            const field = condition.field ?? "";
            const operator = condition.comparator ?? "";
            let valueFromCondition = condition.value ?? "";

            if (this.conditionPassesWithValue(field, operator, valueFromCondition, representativeItem[field] ?? "")) {
                // Skip this part, it doesn't meet the conditions
                passingConditions++;
            }
        }

        return passingConditions > 0;
    }

    public render(representativeItems: any[]): {
        h1: string;
        h2: string;
        h3?: string;
        displayUrl: string;
        finalUrl: string;
        d1: string;
        d2?: string;
    } {
        // We want to be able to pop of parts that won't work, so to prevent the class instance from losing items, we're copying them
        const representativeItem = representativeItems[0];
        const maximumInventoryValue = (field: string): number =>
            Math.max(...representativeItems.filter((item) => item?.[field]).map((item): number => item[field]));
        const minimumInventoryValue = (field: string): number =>
            Math.min(...representativeItems.filter((item) => item?.[field]).map((item): number => item[field]));
        const uniqueInventoryValue = (field: string): any[] =>
            representativeItems.filter((item) => item?.[field]).map((item): number => item[field]);

        representativeItem.model_lowest_price = Math.min(
            ...representativeItems.map((item): number => (item?.OurPrice ?? 0) as number)
        );
        representativeItem.Date = moment();
        representativeItem.dealer_name = "Example Client";
        representativeItem.duplicate_count = representativeItems.length;
        representativeItem.model_discounts = maximumInventoryValue("Discounts");
        representativeItem.model_monthly_payments = minimumInventoryValue("MonthlyPayments");
        representativeItem.model_lease_payments = minimumInventoryValue("LeasePayment");
        representativeItem.model_lowest_miles = minimumInventoryValue("Miles");
        representativeItem.model_highest_year = maximumInventoryValue("Year");
        representativeItem.colors_count = uniqueInventoryValue("Color");
        representativeItem.trim_count = uniqueInventoryValue("Trim");

        // Grab the first item from how each are grouped; that's our representative item
        const renderingHeadlines = [...this.headlines].filter((item) =>
            this.doAnyConditionsPassForPartAndItem(item, representativeItem)
        );
        const renderingDescriptions = [...this.descriptions].filter((item) =>
            this.doAnyConditionsPassForPartAndItem(item, representativeItem)
        );
        const h1s = [...this.h1].filter((item) => this.doAnyConditionsPassForPartAndItem(item, representativeItem));
        const h2s = [...this.h2].filter((item) => this.doAnyConditionsPassForPartAndItem(item, representativeItem));
        const h3s = [...this.h3].filter((item) => this.doAnyConditionsPassForPartAndItem(item, representativeItem));
        const d1s = [...this.d1].filter((item) => this.doAnyConditionsPassForPartAndItem(item, representativeItem));
        const d2s = [...this.d2].filter((item) => this.doAnyConditionsPassForPartAndItem(item, representativeItem));
        const finalUrls = [...this.final_url].filter((item) =>
            this.doAnyConditionsPassForPartAndItem(item, representativeItem)
        );
        const paths = [...this.paths].filter((item) =>
            this.doAnyConditionsPassForPartAndItem(item, representativeItem)
        );

        const h1 = (h1s.pop() ?? renderingHeadlines.pop())?.value ?? "";
        const h2 = (h2s.pop() ?? renderingHeadlines.pop())?.value ?? "";
        const h3 = (h3s.pop() ?? renderingHeadlines.pop())?.value;
        const d1 = (d1s.pop() ?? renderingDescriptions.pop())?.value ?? "";
        const d2 = (d2s.pop() ?? renderingDescriptions.pop())?.value;
        const finalUrl = finalUrls.pop()?.value ?? "";
        const path1 = paths.pop()?.value;
        const path2 = paths.pop()?.value;

        const displayUrl = path1 + "/" + path2;
        return {
            h1: Mustache.render(h1, representativeItem),
            h2: Mustache.render(h2, representativeItem),
            h3: Mustache.render(h3 ?? "", representativeItem),
            d1: Mustache.render(d1, representativeItem),
            d2: Mustache.render(d2 ?? "", representativeItem),
            finalUrl: Mustache.render(finalUrl, representativeItem),
            displayUrl: Mustache.render(displayUrl, representativeItem)
        };
    }

    public isValid(): {
        valid: boolean;
        message: string;
    } {
        const MINIMUM_HEADLINES = 3;
        const MINIMUM_DESCRIPTIONS = 2;
        const pinnedHeadlinesGreaterThanZeroCount: number =
            Number(this.h1.length > 0) + Number(this.h2.length > 0) + Number(this.h3.length > 0);
        const pinnedDescriptionsGreaterThanZeroCount: number = Number(this.d1.length > 0) + Number(this.d2.length > 0);

        const pinnedHeadlinesPlusUnpinned =
            this.unpinned.filter((part) => part.field === "headline").length + pinnedHeadlinesGreaterThanZeroCount;
        const pinnedDescriptionsPlusUnpinned =
            this.unpinned.filter((part) => part.field === "headline").length + pinnedDescriptionsGreaterThanZeroCount;

        const notEnoughPartsMessage = (part: string) => "There are not enough " + part + " parts to create a valid ad.";
        const notEnoughUnpinnedPartsMessage = (position: string, addAdditional: string) =>
            "There are not enough unpinned parts to fill a potential " +
            position +
            " slot in your ad, please add another " +
            addAdditional +
            ".";

        if (this.headlines.length < MINIMUM_HEADLINES) {
            return {
                valid: false,
                message: notEnoughPartsMessage("headline")
            };
        }
        if (this.descriptions.length < MINIMUM_DESCRIPTIONS) {
            return {
                valid: false,
                message: notEnoughPartsMessage("descriptions")
            };
        }
        if (this.final_url.length === 0) {
            return {
                valid: false,
                message: notEnoughPartsMessage("final url")
            };
        }
        if (this.paths.length === 0) {
            return {
                valid: false,
                message: notEnoughPartsMessage("path")
            };
        }

        if (this.h1.length === 0 && pinnedHeadlinesPlusUnpinned < MINIMUM_HEADLINES) {
            return {
                valid: false,
                message: notEnoughUnpinnedPartsMessage("H1", "headline")
            };
        }
        if (this.h2.length === 0 && pinnedHeadlinesPlusUnpinned < MINIMUM_HEADLINES) {
            return {
                valid: false,
                message: notEnoughUnpinnedPartsMessage("H2", "headline")
            };
        }
        if (this.h3.length === 0 && pinnedHeadlinesPlusUnpinned < MINIMUM_HEADLINES) {
            return {
                valid: false,
                message: notEnoughUnpinnedPartsMessage("H3", "headline")
            };
        }
        if (this.d1.length === 0 && pinnedDescriptionsPlusUnpinned < MINIMUM_DESCRIPTIONS) {
            return {
                valid: false,
                message: notEnoughUnpinnedPartsMessage("D1", "description")
            };
        }
        if (this.d2.length === 0 && pinnedDescriptionsPlusUnpinned < MINIMUM_DESCRIPTIONS) {
            return {
                valid: false,
                message: notEnoughUnpinnedPartsMessage("D2", "description")
            };
        }

        return {
            valid: true,
            message: "There appear to be no errors, or missing parts"
        };
    }
}
